@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgray;
}

/* .slick-prev:before,
.slick-next:before {
  color: teal!important;
  line-height: 5;
  font-size: xx-large;
  line-height: 5;
} */

.goal:focus {
  outline: 1px solid #1CB05E;
  outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1CB05E;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border: 1px;
  border-color: #1CB05E;
}

.slick-prev:before,
.slick-next:before {
  color: teal !important;
  font-size: 35px !important;
}

@layer utilities {
  .animation-delay-200 {
    animation-delay: 0.2s;
  }

  .animation-delay-400 {
    animation-delay: 0.4s;
  }
}

.circle {
  @apply w-4 h-4 my-12 mx-1 bg-teal-600 rounded-full;
}

.slick-slider {
  padding-left: 12px;
}

@layer utilities {

  .clip-image {
    clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
  }

}

.btn--glow {
  position:relative;
  overflow:hidden;
}

.btn--glow:before{
  content:'';
  position: absolute;
  left: -20%;
  top:-10%;
  width: 0;
  height: 100%;
  transform: rotate(15deg) scale(1, 2);
  box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.7);
  animation: button-glow 2000ms ease-in-out infinite;
}

@keyframes button-glow {
  0% {
    left: -20%;
  }
  50% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}