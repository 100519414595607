/* Assuming you have a class or element selector */
.notes-parent-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  }
  .learntube-font-family {
    font-family: "Montserrat", sans-serif;
  }
  
  /* Assuming you have a class or element selector */
  .x-font-family {
    font-family: "Lobster";
  }
  .upgrade-button {
    border-radius: 12px;
    background: #1CA571;
    box-shadow: 1.3951px 1.3951px 5.58042px 0px rgba(0, 0, 0, 0.25);
  }