.input1::placeholder{
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    text-align: start;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
.main{
    font-family: 'Inter';
}
.input1{
    outline: none;
    text-align: start;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
}

.input1:focus {
    border-color: #7FC241;
    border : none;
    text-align: start;
    color: white;
    
  }

  .input1::placeholder{
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    text-align: start;
}



.input2{
    outline: none;
    text-align: start;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
}

.input2:focus {
    border-color: #7FC241;
    border : none;
    text-align: start;
    color: white;
    
  }
  .input2::placeholder{
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    text-align: start;
}






.input3::placeholder{
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    text-align: start;
    font-size: 16px;
}

.input3{
    outline: none;
    text-align: start;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
}

.input3:focus {
    border-color: blue;
    outline: none;

  }

  .input4{
    outline: none;
    text-align: start;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
}

.input4{
    outline: none;
    border: none;
    text-align: start;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
}

.input4:focus {
    border-color: none;

    border : none;

  }

  .input{
    outline: none;
    text-align: start;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
}
.scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }