.learntube-font-family {
    font-family: "Montserrat", sans-serif;
  }
  
  /* Assuming you have a class or element selector */
  .x-font-family {
    font-family: "Lobster";
  }
  
  /* Assuming you have a class or element selector */
.feedback-parent-shadow {
    background: #D8F1FF;
    box-shadow: 0px 1.52925px 4.58774px 0px rgba(0, 0, 0, 0.25);
  }
  
  .upgrade-button {
    border-radius: 12px;
    background: #1CA571;
    box-shadow: 1.3951px 1.3951px 5.58042px 0px rgba(0, 0, 0, 0.25);
  }

  /* Assuming you have a class or element selector */
.quiz-complete {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.17);
  }
  /* Assuming you have a class or element selector */
.mskillcomplete-parent {
    box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.13);
  }
  

/* Assuming you have a class or element selector */
.mskillcomplete-premium-feature {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), 
                linear-gradient(91.75deg, #C1A43F 0.83%, #FDF9A2 101.63%);
  }
  

  /* Assuming you have a class or element selector */
.report-button {
    background: linear-gradient(238.65deg, #F59E0B 16.15%, #FFCE31 86.12%);
  }
  