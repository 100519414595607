.slick-slider {
    padding-left: 0px;
}
.arrow-scroll>div>.slick-prev:before {
    color: #13B384 !important;
}

.arrow-scroll>div>.slick-next:before {
    color: #13B384 !important;
}
.job-ready-linear-gradient {
    background: linear-gradient(238.65deg, #13A0BD 20%, #086788 89.97%);
}

.premium-gradient-boxshadow {
    background: linear-gradient(90deg, #FEF08A 0%, #FACC15 50%, #CA8A04 100%);
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.aboutus-box-shadow {
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.35);
}

.appHighlights-box-shadow {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
}

.basedonYoursearch-box-shadow {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
}
.default-box-shadow {
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
}
