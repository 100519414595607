/* .input1 {
    text-align: center;
    color: white; 
    text-align: center;
    
  }
  
 
  .input1::placeholder {
    color: rgba(255, 255, 255, 0.30); 
    font-weight: normal;
    
  } */
  .input:focus {
    outline: 1px solid #1CA571;
    border-color: #1CA571; /* Border color when in focus */
    outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1CA571;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }

 

  
  .progress-bar {
    width: 180px;
    height: 4px;
    background-color: #ddd;
    /* margin: 0 5px; */
    position: relative;
  }
  
  .progress-line {
    height: 4px;
    background-color: #1CA571;
   
  }
  
  .progress-circle {
    width: 10px;
    height: 10px;
    background-color:#1CA571;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    transform: translate(-50%, -50%);
    
  }
  
  .progress-time {
    /* margin-left: 10px; */
    font-size: 10px;
    
  }
  
  .doubt-parent-box-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
}


.learntube-font-family {
  font-family: "Montserrat", sans-serif;
}

/* Assuming you have a class or element selector */
.x-font-family {
  font-family: "Lobster";
}

/* Assuming you have a class or element selector */
.audio-card-shadow {
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.25);
}

/* Assuming you have a class or element selector */
.upgrade-button {
  border-radius: 12px;
  background: #1CA571;
  box-shadow: 1.3951px 1.3951px 5.58042px 0px rgba(0, 0, 0, 0.25);
}
