input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 15px;
  z-index: 5;
}

input[type=range]::-webkit-slider-runnable-track {
  background: #cfcfcf;
  height: 12px;
  pointer-events: none;
}

input[type=range]::-webkit-slider-thumb {
  height: 12px;
  width: 150px;
  -webkit-appearance: none;
  appearance: none;
  background: #3e3e3e;
  border-radius: 8px;
}

.slick-slide > div {
  margin: 0rem 0rem !important;
}
