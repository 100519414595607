.slick-prev::before {
    margin-left: -15px;
}

.slick-prev:before{
  color: white !important;
  font-size: 35px !important;
}

.slick-next:before {
    color: white !important;
    font-size: 35px !important;
}