.scrollbar{
    scrollbar-color: dimgray white;
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
		width: 2px;
		height: 1px;
	}

::-webkit-scrollbar-track {
		background-color: black;
	}

::-webkit-scrollbar-thumb {
		/* background-color: rgb(#fff, 0.4); */ 
	--tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity));
	border-radius: 8px;
	outline: none;
}

.sidemenu-scrollbar::-webkit-scrollbar{
	display: none;
}


@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
.lobster-regular {
	font-family: "Lobster", sans-serif;
	font-weight: 400;
	font-style: normal;
  }
  