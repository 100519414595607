input::placeholder{
    color: #87A7B2
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}
.search-box {
  border-radius: 8px;
  box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.10);
}
