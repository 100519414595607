.scrollable-div::-webkit-scrollbar {
    width: 5px; /* Set the width of the scrollbar */
    background-color: #F5F5F5; /* Set the background color of the scrollbar */
    position: absolute;
    right: 200px; /* Position the scrollbar on the left of the element */
 }
 .scrollable-div::-webkit-scrollbar-thumb {
    background-color:  #AFAFAF;
 }
 ::-webkit-scrollbar-track {
    background: #ECECEC;
    border-radius: 30px;
 }
