/* Define styles for the input */
.inputDefault1 {
    text-align: center;
    color: white; 
    text-align: center;
    
  }
  
 
  .inputDefault1::placeholder {
    color: rgba(255, 255, 255, 0.30); 
    font-weight: normal;
    
  }
  .inputDefault1:focus {
    outline: 1px solid #7FC241;
    border-color: #7FC241; /* Border color when in focus */
    outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .inputDefault2 {
    text-align: center;
    color: white; 
    text-align: center;
  }
  
 
  .inputDefault2::placeholder {
    color: rgba(255, 255, 255, 0.30); 
    font-weight: normal;
    
  }
  .inputDefault2:focus {
    outline: 1px solid #7FC241;
    border-color: #7FC241; /* Border color when in focus */
    outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }

  .inputDefault3 {
    text-align: center;
    color: white; 
    text-align: center;
  }
  
 
  .inputDefault3::placeholder {
    color: rgba(255, 255, 255, 0.30); 
    font-weight: normal;
  } 
  
  .inputDefault3:focus {
    outline: 1px solid #7FC241;
    border-color: #7FC241; /* Border color when in focus */
    outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  .inputDefault4 {
    text-align: center;
    color: white; 
    text-align: center;
  }
  
  .inputDefault4:focus {
    outline: 1px solid #7FC241;
    border-color: #7FC241; /* Border color when in focus */
    outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #7FC241;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
 
  .inputDefault4::placeholder {
    color: rgba(255, 255, 255, 0.30); 
    font-weight: normal;
  }


  .inputRed {
    text-align: center;
    color: white; 
    text-align: center;
  }
  
  .inputRed:focus {
    outline: 1px solid #FF4F55;
    border-color: #FF4F55; /* Border color when in focus */
    outline-offset: 1px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #FF4F55;
  --tw-ring-color: #FF4F55;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
 
  .inputRed::placeholder {
    color: rgba(255, 255, 255, 0.30); 
    font-weight: normal;
  }

  .radial-gradient-background {
    background: radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%);
}

.semi-transparent-text {
  color: rgba(255, 255, 255, 0.50);
}


.footer-shadow {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
