/* SITE TOUR HELPER CONTAINER STYLE */

.mask {
    background: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
}

/* SITE TOUR CONTENT STYLE */

.siteTour__container {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.siteTour__container img {
    height: 6rem;
    width: 6rem;
    object-fit: contain;
    position: relative;
}

.siteTourContent {
    padding: 0.5rem;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(55, 68, 69, 0.5);
    border: 4px solid #5F696A;
    color: white;
    font-weight: bolder;
    backdrop-filter: blur(5px);
    border-radius: 0.7rem;
    text-align: center;
    font-size: smaller;

}

/* SITE TOUR CONTROL STYLE */

.siteTour__controls {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem 0rem;
}

.siteTour__button {
    background: none;
    border: none;
    color: white;
    padding: 0;
    font-weight: bolder;
    font-size: 0.9rem;
}


.siteTour-mobile img {
    height: 5rem;
    width: 5rem;
    object-fit: contain;
    position: relative;
}

.img--left {
    align-items: flex-start;
}

.img--center img {
    transform: scale(1.5);
}

.img--right {
    align-items: flex-end;
}


@media screen and (max-width:981px) {

    .siteTour__container img {
        height: 5rem;
        margin-bottom: 0.5rem;
        width: 100%;
    }

    .siteTour__container .siteTourContent {
        margin: 0;
        font-size: x-small;
    }

}