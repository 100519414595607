.ProseMirror-focused{
    outline: none;
}

 pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
  }

pre code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
}

h1.tiptap-heading {
    font-weight: bolder;
    font-size: 1.5rem;
}

h2.tiptap-heading{
    font-weight: bold;
    font-size: 1.2rem;
}

ul.tiptap-bulletList{
    list-style:disc;
    padding-left: 2rem;
}

ol.tiptap-orderedList{
    list-style:decimal;
    padding-left: 2rem;
}

blockquote.tiptap-blockquote {
    border-left: 3px solid gray;
    margin: 1rem 2rem;
    padding-left: 1rem;
  }

  hr{
      font-weight: bold;
      padding: 0.02rem;
      margin: 1rem 0rem;
      background: gray;
  }

.ProseMirror p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.note-input::placeholder {
    color: rgba(255, 255, 255, 0.50); /* Set the color of the placeholder text */
    font-style: normal; /* Optionally, you can set other styles like font-style */
    font-weight: 600;
    font-size : 14px;
  }

.note-input{
    outline: none;
    border: 0px;
}

.note-input:focus{
    outline: 0px solid #7FC241;
    outline-offset: 0px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #7FC241;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: 0px 0px 0px 0px #7FC241;
    border: 0px;
    border-color: #7FC241;
}

.note-input{
    color: #1CA571
}
