.password-input::placeholder {
    color: rgba(255, 255, 255, 0.50); /* Set the color of the placeholder text */
    font-style: normal; /* Optionally, you can set other styles like font-style */
    font-weight: 600;
    font-size : 14px;
  }

.password-input{
    outline: none;
    border: 0px;
}

.password-input:focus{
    outline: 0px solid #7FC241;
    outline-offset: 0px;
    --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #7FC241;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: 0px 0px 0px 0px #7FC241;
    border: 0px;
    border-color: #7FC241;
}