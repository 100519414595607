.bottom-card-gradient{
    background: linear-gradient(125deg, #3877B6 0%, #1C3B5B 100%);
}

.parent-radial-gradient-background {
    background: radial-gradient(50% 50% at 50% 50%, #26496A 0%, #182C3F 100%);
}

.pricing-card-linear-gradient-background {
    background: linear-gradient(125deg, #3877B6 0%, #1C3B5B 100%);
}