.progress {
    animation: progress 1s infinite linear;
  }
  
  .left-right {
      transform-origin: 0% 50%;
  }
      @keyframes progress {
      0% {
          transform:  translateX(0) scaleX(0);
      }
      40% {
          transform:  translateX(0) scaleX(0.4);
      }
      100% {
          transform:  translateX(100%) scaleX(0.5);
      }
  }

  .personalize-box-shadow {
    box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.25);
}

.lesson-box-shadow {
    box-shadow: 0px 0px 14px 3px rgba(0, 0, 0, 0.25);
}
